import Vue from 'vue'
import Component from 'vue-class-component'

export enum QueryType {
    String = 'string',
    Number = 'number',
    Boolean = 'boolean',
    Date = 'date',
    Custom = 'custom',
}

export type QueryDef = {
    localVar: string
    queryStr?: string
    type?: QueryType

    localToQuery?: (value: any) => string | undefined,
    queryToLocal?: (str: string) => (any)
}

function dateToStr(date: Date | null): string | undefined {
    if (!date)
        return undefined
    return date.toLocaleDateString('en-GB').replace(/\//g, '-')
}

function strToDate(str: string): Date {
    const splited = str.split('-')
    return new Date(
        Number(splited[2]),
        Number(splited[1]) - 1,
        Number(splited[0]))
}

// https://dmitripavlutin.com/how-to-compare-objects-in-javascript/
function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
}

@Component
export default class QueryParserMixin extends Vue {
    queryDef: QueryDef[] = []

    parseQuery() {
        for (const d of this.queryDef) {
            const queryStr = d.queryStr || d.localVar
            const value = this.$route.query[queryStr]
            if (value) {
                switch (d.type) {
                    case QueryType.String:
                        this[d.localVar] = value
                        break
                    case QueryType.Number:
                        const n = Number(value)
                        if (!isNaN(n))
                            this[d.localVar] = n
                        break
                    case QueryType.Boolean:
                        this[d.localVar] = value !== "false"
                        break
                    case QueryType.Date:
                        this[d.localVar] = strToDate(value as string)
                        break
                    case QueryType.Custom:
                        if (d.queryToLocal)
                            this[d.localVar] = d.queryToLocal(value as string)
                        break
                    default:
                        this[d.localVar] = value
                        break
                }
            }
        }
    }

    async setQuery(push = true) {
        const query = {}
        for (const d of this.queryDef) {
            const queryStr = d.queryStr || d.localVar
            const v = (d.type === QueryType.Custom && d.localToQuery) ? d.localToQuery(this[d.localVar]) :
                (d.type === QueryType.Date)? dateToStr(this[d.localVar]) : this[d.localVar] + ''
            if (v) {
                query[queryStr] = v
            }
        }

        if(!shallowEqual(this.$route.query, query)) {
            if(push)
                return this.$router.push({query: query})
            else
                return this.$router.replace({query: query})
        }
    }
}
