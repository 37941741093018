var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('el-header',{staticClass:"p-3"},[_c('el-row',{staticClass:"row-bg",attrs:{"type":"flex","justify":"space-between"}},[_c('el-row',{staticClass:"row-bg",attrs:{"type":"flex","align":"middle"}},[_c('div',{staticClass:"mr-3"},[_c('el-checkbox',{staticClass:"mb-0",attrs:{"label":"show hidden"},on:{"change":function($event){return _vm.updateShopItems()}},model:{value:(_vm.showHidden),callback:function ($$v) {_vm.showHidden=$$v},expression:"showHidden"}})],1)]),_c('div',{staticClass:"mr-3"},[_c('router-link',{attrs:{"to":"/shop/new-item"}},[_c('el-button',[_vm._v("Add")])],1)],1)],1)],1),_c('el-main',[_c('div',{attrs:{"align":"center"}},[(_vm.totalCount / _vm.step > 1)?_c('el-pagination',{staticClass:"m-2",attrs:{"layout":"prev, pager, next","total":_vm.totalCount,"page-size":_vm.step,"current-page":_vm.currentPage},on:{"current-change":_vm.toPage}}):_vm._e()],1),_c('el-table',{staticClass:"mb-3",staticStyle:{"width":"100%"},attrs:{"data":_vm.items,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"title","label":"Title"}}),_c('el-table-column',{attrs:{"prop":"price","label":"Images"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-carousel',{staticStyle:{"width":"200px"},attrs:{"type":"card","trigger":"click","height":"100px"}},_vm._l((row.image_urls),function(url){return _c('el-carousel-item',{key:url},[_c('img',{staticClass:"shop-img",attrs:{"src":url}})])}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"prefix-icon":"as-coin coin"},on:{"focus":function($event){return _vm.priceInputFocus(row)},"blur":function($event){return _vm.priceInputBlurred(row)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.changedPrice(row)}},model:{value:(row.price),callback:function ($$v) {_vm.$set(row, "price", _vm._n($$v))},expression:"row.price"}})]}}])}),_c('el-table-column',{attrs:{"label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticClass:"select-arrow",attrs:{"placeholder":"Status"},on:{"change":function($event){return _vm.statusChanged(_vm.$index, row)}},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}},_vm._l((_vm.statusOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"Actions","width":"130px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":("/shop/item/" + (row._id))}},[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-edit"}})],1),_c('el-button',{staticClass:"ml-1",attrs:{"icon":"el-icon-delete","size":"mini","type":"danger"},on:{"click":function($event){return _vm.deleteItem(row)}}})]}}])})],1)],1),_c('el-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }