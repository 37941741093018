













































































































import {Component} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {ShopItem, ShopItemStatus} from '~/components/data-class/data-class'
import {isEmpty} from '~/utils/misc'
import {ShopPermission} from '~/utils/permissions'
import {mixins} from 'vue-class-component'
import QueryParserMixin, {QueryType} from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Shop Item List'
        }
    }
})

export default class ShopItemList extends mixins(QueryParserMixin, PaginationMixin) {
    static permission = ShopPermission.EditShopItems

    queryDef = [
        {localVar: 'showHidden', queryStr: 'hidden', type: QueryType.Boolean},
        {localVar: 'currentPage', queryStr: 'page', type: QueryType.Number},
    ]

    statusOptions = [
        {label: 'In Stock', value: ShopItemStatus.IN_STOCK},
        {label: 'Out of Stock', value: ShopItemStatus.OUT_OF_STOCK},
        {label: 'Hidden', value: ShopItemStatus.HIDDEN},
    ]

    items: ShopItem[] = []
    selectedStatus: number | null = null
    showHidden = true

    tempPrice: number = 0

    // Change Price

    changedPrice(row) {
        const body: {} = {
            price: row.price
        }

        const id = row._id
        createRequest(`/shop/item/${id}`, 'patch', {}, body).send()
            .then((res) => {
                this.$message({
                    message: 'Item price changed successfully!',
                    type: 'success'
                })
                this.tempPrice = row.price
            })
            .catch((e) => {
                this.$message({
                    message: 'Error occurred!',
                    type: 'error'
                })
            })
    }

    priceInputFocus(row) {
        this.tempPrice = row.price
    }

    priceInputBlurred(row) {
        row.price = this.tempPrice
    }

    // Change Status

    statusChanged(index, row) {
        const body: {} = {
            status: row.status
        }

        const id = row._id
        createRequest(`/shop/item/${id}`, 'patch', {}, body).send()
            .then((res) => {
                this.$message({
                    message: 'Item status changed successfully!',
                    type: 'success'
                })
            })
            .catch((e) => {
                this.$message({
                    message: 'Error occurred!',
                    type: 'error'
                })
            })
    }
    async created() {
        this.parseQuery()
        await this.updateShopItems()
    }
    async toPage(n) {
        this.currentPage = n
        await this.updateShopItems()
    }
    async updateShopItems() {
        const params: { [key: string]: any } = {
            skip: (this.currentPage - 1) * this.step,
            limit: this.step
        }
        if (!isEmpty(this.selectedStatus)) {
            params['status'] = this.selectedStatus
        }
        if (this.showHidden) {
            params['hidden'] = true
        }

        const res = await createRequest('/shop/items', 'get', params).send()
        this.items = res.data.items
        this.totalCount = res.data.count

        this.setQuery()
    }
    async deleteItem(item: ShopItem) {
        try {
            await this.$confirm(`Sure to delete ${item.title}?`)

            await createRequest(`/shop/item/${item._id}`, 'delete').send()
            await this.updateShopItems()
        } catch {

        }
    }
}

